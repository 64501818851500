import React, { Component } from 'react'
export class Posts extends Component {
    constructor(){
        super();
        this.state = {
            posts: []
        }
    }

    componentDidMount () {
        let postURL = 'https://apitest.techtalksbynoah.com/wp-json/wp/v2/posts/'
        fetch(postURL)
        .then(response => response.json())
        .then(response => {
            this.setState({
                posts: response
            })
        })
    }

    render() {
        let posts = this.state.posts.map((post, index) => {
            return(
                <div id="postContainer" key={index}>
                    <div id="postItem">
                        <div id="featuredImage">

                        </div>
                        <div id="postMeta">
                            <h4>{post.title.rendered}</h4>
                            <p dangerouslySetInnerHTML={{__html: post.excerpt.rendered}}></p>
                        </div>
                    </div>
                </div>
            )
        })
        return (
            <>
                {posts}
            </>
        )
    }
    
}
export default Posts