import React, { Component } from 'react'
export class Header extends Component {

    render() {
        return (
            <>
                <header id="header">
                    <div id="headerLeft">

                    </div>
                    <div id="headerRight">
                        <nav>
                            <a className="menuItem" href="./../posts">
                                Recent Posts
                            </a>
                        </nav>
                    </div>
                </header>
            </>
        )
    }
    
}
export default Header